// src/services/communityDetailsServices.ts
import requests from "../Axios/Instance"; // Adjust the path according to your project structure

export interface CommunityDetailsResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}

const CommunityDetailsService = {
    getCommunityDetails: async () => {
        return requests.get('/community/communityDetails');
    },
};

export default CommunityDetailsService;
