import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllPlayerList } from "../actions"; 

interface Player {
    skip: number;
    limit: number;
    sort:string;
    search?: string;
    minAge:number;
    maxAge:number;
    city:string;
    status:string;
    [key: string]: any;  
}

interface PlayerListState {
    players: Player[];
    loading: boolean;
    error: string | null;
}

 
interface ResponseData {
    success: boolean;
    data: Player[];  
    message: string;
}

const initialState: PlayerListState = {
    players: [],
    loading: false,
    error: null,
};

const playerListSlice = createSlice({
    name: "playerList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllPlayerList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllPlayerList.fulfilled, (state, action: PayloadAction<ResponseData>) => {
                state.loading = false;
                state.players = action.payload.data;  
            })
            .addCase(getAllPlayerList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to fetch player list";
            });
            
    },
});

export default playerListSlice.reducer;
