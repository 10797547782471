import React, { useState, useContext, ReactNode } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { unwrapResult, AnyAction } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';

import { customerLogout } from "../../Store/actions";
import { demoPagesMenu } from '../../menu';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import ThemeContext from '../../contexts/themeContext';
import { AppDispatch } from '../../Store/Store';
 

const User = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { width } = useWindowSize();
	const navigate = useNavigate();
	const { setAsideStatus } = useContext(ThemeContext);
	const { userData } = useContext(AuthContext);
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	const handleLogout = async () => {
		try {
			// Dispatch the logout action
			const resultAction = await dispatch(customerLogout());
	
			// Unwrap the result using unwrapResult
			const originalPromiseResult = unwrapResult(resultAction);
	
			// Handle success
			toast.success("Logged out successfully", {
				position: "top-right",
				theme: 'colored'
			});
			navigate("/"); // Redirect to login page or home page
		} catch (error) {
			if (error instanceof Error) {
				toast.error(error.message || "Failed to log out", {
					position: "top-right",
					theme: 'colored'
				});
			} else {
				toast.error("An unknown error occurred", {
					position: "top-right",
					theme: 'colored'
				});
			}
		}
	};

	
	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<img
						srcSet={userData?.srcSet}
						src={userData?.src}
						alt='Avatar'
						width={128}
						height={128}
					/>
				</div>
				<div className='user-info'>
					<div className='user-name d-flex align-items-center'>
						{`${userData?.name} ${userData?.surname}`}
					</div>
					{/* <div className='user-sub-title'>{userData?.position}</div> */}
				</div>
			</div>
			<DropdownMenu>
				<DropdownItem>
					<Button
						icon='AccountBox'
						onClick={() =>
							navigate(
								`../${demoPagesMenu.appointment.subMenu.employeeID.path}/${userData?.id}`,
							)
						}>
						Profile
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
			</DropdownMenu>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`../${demoPagesMenu.appointment.subMenu.employeeID.path}/${userData?.id}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Profile') as ReactNode}
									</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus
											? (t('menu:DarkMode') as ReactNode)
											: (t('menu:LightMode') as ReactNode)}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							// onClick={() => {
							// 	if (setUser) {
							// 		setUser('');
							// 	}
							// 	if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)) {
							// 		setAsideStatus(false);
							// 	}
							// 	navigate(`../${demoPagesMenu.login.path}`);
							// }}
							onClick={handleLogout}
							>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Logout') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
