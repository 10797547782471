// src/slices/communityDetailsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getCommunityDetails } from "../actions"; // Adjust the path according to your project structure

export interface CommunityDetailsResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}

interface CommunityDetailsState {
    data: any; // Replace `any` with the type of your data
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: CommunityDetailsState = {
    data: null,
    status: 'idle',
    error: null,
};

const communityDetailsSlice = createSlice({
    name: 'communityDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCommunityDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCommunityDetails.fulfilled, (state, action: PayloadAction<CommunityDetailsResponseData>) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                console.log("state.data",state.data);
                
                 // Adjust if the structure of the payload differs
            })
            .addCase(getCommunityDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch data';
            });
    },
});

 

// Export reducer
export default communityDetailsSlice.reducer;
