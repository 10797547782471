import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getDashboardTotal } from "../actions";// Adjust the path according to your project structure

export interface DashboardTotalResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}

interface DashboardState {
    data: any; // Replace `any` with the type of your data
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: DashboardState = {
    data: null,
    status: 'idle',
    error: null,
};



const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardTotal.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getDashboardTotal.fulfilled, (state, action: PayloadAction<DashboardTotalResponseData>) => {
                state.status = 'succeeded';
                state.data = action.payload.data; // Adjust if the structure of the payload differs
            })
            .addCase(getDashboardTotal.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch data';
            });
    },
});

// Export selectors
export const selectDashboardData = (state: { dashboard: DashboardState }) => state.dashboard.data;
export const selectDashboardStatus = (state: { dashboard: DashboardState }) => state.dashboard.status;
export const selectDashboardError = (state: { dashboard: DashboardState }) => state.dashboard.error;

// Export reducer
export default dashboardSlice.reducer;
