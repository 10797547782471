import { createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { getFollowerFollowing } from "../actions";

export interface FollowerFollowing {
    userId: string;
    type: string;
    skip: number;
    limit: number;
}

export interface User {
    firstName: string;
    lastName: string;
    profile_image: string;
    status: boolean;
    userName: string;
    _id: string;
}

export interface FollowerFollowingListResponseData {
    success: boolean;
    data: User[]; // Assuming data is an array of User
    message: string;
}

interface FollowerFollowingState {
    loading: boolean;
    followerFollowingList: User[] | null; // Adjust type if needed
    success: boolean;
    error: string | null;
    totalCount: number;
}

const initialState: FollowerFollowingState = {
    loading: false,
    followerFollowingList: null,
    success: false,
    error: null,
    totalCount: 0,
};

const FollowerFollowingListSlice = createSlice({
    name: "followerFollowingList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFollowerFollowing.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getFollowerFollowing.fulfilled, (state, action: PayloadAction<FollowerFollowingListResponseData>) => {
                const { data, success } = action.payload;
                state.followerFollowingList = data;
                state.success = success;
                state.loading = false;
                state.error = null;
            })
            .addCase(getFollowerFollowing.rejected, (state, action: { payload: any; error: SerializedError }) => {
                state.loading = false;
                state.error = action.payload?.message || action.error.message || "Failed to fetch follower/following list";
                state.success = false;
            });
    },
});

export default FollowerFollowingListSlice.reducer;
