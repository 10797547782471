import { createSlice } from '@reduxjs/toolkit';
import { getFeedPostStory, getEventBadge, getMessage } from '../actions';

export interface UserIdData {
    userId: string;
}


interface UserState {
    feedPostStory: any;
    eventBadge: any;
    messages: any;
    loading: boolean;
    error: string | null;
}

const initialState: UserState = {
    feedPostStory: null,
    eventBadge: null,
    messages: null,
    loading: false,
    error: null,
};

const userSlice = createSlice({
    name: 'userplayer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Handle getFeedPostStory
        builder.addCase(getFeedPostStory.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getFeedPostStory.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.feedPostStory = payload;
        });
        builder.addCase(getFeedPostStory.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload as string;
        });

        // Handle getEventBadge
        builder.addCase(getEventBadge.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getEventBadge.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.eventBadge = payload;
        });
        builder.addCase(getEventBadge.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload as string;
        });

        // Handle getMessage
        builder.addCase(getMessage.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getMessage.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.messages = payload;
        });
        builder.addCase(getMessage.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload as string;
        });
    },
});

export default userSlice.reducer;
