 
import requests from "../Axios/Instance";

 
export interface PlayersListData {  
    skip: number;
    limit: number;
    sort:string;
    search?: string;
    minAge?:number;
    maxAge?:number;
    city?:string;
    status?:string;
}
export interface PlayerListData {  
    _id: string;
    skip: number;
    limit: number;
}

const PlayerListService = {
    getAllPlayerList: async (data: PlayersListData) => {
        return requests.get(`/user/playerList?skip=${data.skip}&limit=${data.limit}&sort=${data.sort}&search=${data.search}&minAge=${data.minAge}&maxAge=${data.maxAge}&city=${data.city}&status=${data.status}`);
    },
    getPlayerList: async (data: PlayerListData) => {
        return requests.get(`/user/playerList?_id=${data._id}&skip=${data.skip}&limit=${data.limit}`);
    },
};

export default PlayerListService;


