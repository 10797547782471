import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAgeGenderStatistics } from "../actions";

// Define the structure of the statistics data
interface AgeGenderStatistics {
    ageStatistics: {
        '18_25': number;
        '25_30': number;
        '30_35': number;
        '35_plus': number;
        'below_18': number;
    };
    genderStatistics: {
        female: number;
        male: number;
    };
    period: string;
}

interface ApiResponse {
    success: boolean;
    message: string;
    data: AgeGenderStatistics;
}

interface AgeGenderStatisticsState {
    loading: boolean;
    data: AgeGenderStatistics | null;
    error: string | null;
}

const initialState: AgeGenderStatisticsState = {
    loading: false,
    data: null,
    error: null,
};

const AgeGenderStatisticsSlice = createSlice({
    name: "ageGenderStatistics",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAgeGenderStatistics.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAgeGenderStatistics.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
                if (action.payload.success) {
                    state.data = action.payload.data; // Accessing the nested `data` field
                } else {
                    state.error = action.payload.message;
                }
                state.loading = false;
            })
            .addCase(getAgeGenderStatistics.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string || "Failed to fetch age and gender statistics";
            });
    },
});

export default AgeGenderStatisticsSlice.reducer;
