import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAdminTeam } from "../actions";

// Define the AdminTeamResponseData interface
export interface AdminTeamResponseData {
    success: boolean;
    data: any;  
    message: string;
}

// Define the AdminTeamState
interface AdminTeamState {
    adminTeam: any; 
    loading: boolean;
    error: string | null;
}

const initialState: AdminTeamState = {
    adminTeam: null, // Initialize adminTeam state
    loading: false,
    error: null,
};

const AdminTeamSlice = createSlice({
    name: "adminTeam",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAdminTeam.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAdminTeam.fulfilled, (state, action: PayloadAction<AdminTeamResponseData>) => {
                state.loading = false;
                state.adminTeam = action.payload.data; // Correctly assign to adminTeam
            })
            .addCase(getAdminTeam.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to fetch admin team";
            });
    },
});

 
export default AdminTeamSlice.reducer;