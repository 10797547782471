import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTournaments } from "../actions";

export interface TournamentRequestData {
    sort: {
        createdAt: number;
    };
    skip: number;
    limit: number;
    communityId: string;
}

export interface TournamentResponseData {
    success: boolean;
    data: any; // Adjust according to the actual response data structure
    message: string;
}

interface TournamentState {
    tournaments: any[]; // Adjust according to your actual tournament data structure
    loading: boolean;
    error: string | null;
}

const initialState: TournamentState = {
    tournaments: [],
    loading: false,
    error: null,
};

const tournamentSlice = createSlice({
    name: "tournament",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTournaments.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getTournaments.fulfilled, (state, action: PayloadAction<TournamentResponseData>) => {
                state.loading = false;
                state.tournaments = action.payload.data; // Assuming the data field contains the tournaments array
            })
            .addCase(getTournaments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to fetch tournaments";
            });
    },
});

export default tournamentSlice.reducer;
