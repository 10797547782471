// src/slices/advertisementSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAdvertisementList, getSponsorshipList } from '../actions';

// Define initial state
interface Advertisement {
    _id: string;
    type: string;
    skip: number;
    limit: number;
    // Add other fields based on your API response
}

interface AdvertisementListState {
    advertisements: Advertisement[];
    loading: boolean;
    error: string | null;
}

interface AdvertisementParams {
    success: boolean;
    data: Advertisement[];  // Assuming `data` is an array of `Player`
    message: string;
}

const initialState: AdvertisementListState = {
    advertisements: [],
    loading: false,
    error: null,
};

// Create slice
const advertisementSlice = createSlice({
    name: 'advertisements',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAdvertisementList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAdvertisementList.fulfilled, (state, action: PayloadAction<AdvertisementParams>) => {
                state.loading = false;
                state.advertisements = action.payload.data;
            })
            .addCase(getAdvertisementList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch advertisements';
            })
            .addCase(getSponsorshipList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSponsorshipList.fulfilled, (state, action: PayloadAction<AdvertisementParams>) => {
                state.loading = false;
                state.advertisements = action.payload.data;
            })
            .addCase(getSponsorshipList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch sponsorships';
            });
    },
});

export default advertisementSlice.reducer;
