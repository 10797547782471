import React, { FC, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
 
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import { RootState } from '../../../Store/Store';
import { customerSignIn } from '../../../Store/actions';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}

const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

LoginHeader.defaultProps = {
	isNewUser: false,
};

	
const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<any>();
	const { isLoading, errorMessage, isLoggedIn } = useSelector((state: RootState) => state.AuthLogin);
	const { darkModeStatus } = useDarkMode();

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validate: (values) => {
			const errors: { email?: string; password?: string } = {};
			if (!values.email) {
				errors.email = 'Required';
			}
			if (!values.password) {
				errors.password = 'Required';
			}
			return errors;
		},
		onSubmit: (values) => {
			dispatch(customerSignIn(values));
		},
	});

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/dashboard');
		}
		else {
			navigate('/');
		}
	}, [isLoggedIn, navigate]);

	return (
		<PageWrapper isProtected={false} title='Login' className='login-background'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo width={200} />
									</Link>
								</div>

								<Alert isLight icon='Lock' isDismissible>
									<div className='row'>
										<div className='col-12'>
											<strong>Username:</strong>superAdmin@gmail.com
										</div>
										<div className='col-12'>
											<strong>Password:</strong>@abc123
										</div>
									</div>
								</Alert>

								<form className='row g-4' onSubmit={formik.handleSubmit}>
									<div className='col-12'>
										<FormGroup id='email' isFloating label='Email'>
											<Input
												type='email'
												autoComplete='username'
												value={formik.values.email}
												isTouched={formik.touched.email}
												invalidFeedback={formik.errors.email}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='email'
											/>
										</FormGroup>
										<FormGroup id='password' isFloating label='Password' className='mt-4'>
											<Input
												type='password'
												autoComplete='current-password'
												value={formik.values.password}
												isTouched={formik.touched.password}
												invalidFeedback={formik.errors.password}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='password'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button color='primary' className='w-100 py-3' type='submit'>
											{isLoading ? 'Logging in...' : 'Login'}
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

 

export default Login;
