import axios, { AxiosResponse, AxiosError, InternalAxiosRequestConfig } from "axios";

const BaseUrl = "http://play2plus.prometteur.in:6161/admin/v1";

const instance = axios.create({
    baseURL: BaseUrl,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const user = localStorage.getItem("user");
    console.log("Raw user from localStorage:", user);

    // Parse the user object correctly
    const parsedUser = user ? JSON.parse(user) : null;
    console.log("Parsed user:", parsedUser);

    const token = parsedUser?.token;
    console.log("Retrieved Token:", token);

    if (token) {
        config.headers.Authorization = `Bearer ${token}`; // Use dot notation here
    } else {
        console.warn("No token found in localStorage.");
    }

    console.log("Request Headers:", config.headers);
    return config;
});

instance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error: AxiosError) => {
        if (error.response) {
            console.error("Response Error:", error.response.data);
        }
        return Promise.reject(error);
    }
);

export default instance;
