import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getFollowerFollowingList } from '../actions';


// Define the initial state for the slice

export interface User {
    firstName: string;
    lastName: string;
    profile_image: string;
    status: boolean;
    userName: string;
    _id: string;
}


export interface FollowerFollowingListResponseData {
    success: boolean;
    data: {
        follower: User[];
        following: User[];
    };
    message: string;
}


interface FollowerFollowingState {
    loading: boolean;
    error: string | null;
    data: {
        follower: User[];
        following: User[];
    };
    success: boolean;
}

// Initial state of the follower/following list
const initialState: FollowerFollowingState = {
    loading: false,
    error: null,
    data: {
        follower: [],
        following: []
    },
    success: false,
};

// Create the slice
const followerFollowingSlice = createSlice({
    name: 'followerFollowing',
    initialState,
    reducers: {
        // You can add additional synchronous reducers here if needed
    },
    extraReducers: (builder) => {
        builder

            .addCase(getFollowerFollowingList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })

            .addCase(getFollowerFollowingList.fulfilled, (state, action: PayloadAction<FollowerFollowingListResponseData>) => {
                state.loading = false;
                state.data = action.payload.data; // Adjust based on the actual data structure
                state.success = action.payload.success;
            }
            )

            .addCase(getFollowerFollowingList.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload.message || 'Failed to fetch follower/following list';
                state.success = false;
            }
            );
    },
});

export default followerFollowingSlice.reducer;
