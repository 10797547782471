import requests from "../Axios/Instance";

// Define the TournamentRequestData interface to shape the request data
export interface TournamentRequestData {
    sort: {
        createdAt: number;
    };
    skip: number;
    limit: number;
    communityId: string;
}

// Define the TournamentResponseData interface to shape the response data
export interface TournamentResponseData {
    success: boolean;
    data: any; // Adjust according to the actual response data structure
    message: string;
}

const TournamentService = {
    getTournaments: async (data: TournamentRequestData) => {
        return requests.post(`/tournament/getTournament`, data);
    },
};

export default TournamentService;
