import requests from "../Axios/Instance"; // Adjust the path according to your project structure

export interface DashboardParams {
    // Define any parameters if needed
}

export interface DashboardTotalResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}

const DashboardService = {
    getDashboardTotal: async () => {
        return requests.get('/dashboard/dashboardTotal');
    },
};

export default DashboardService;
