import requests from "../Axios/Instance";

// Define the interface for your request parameters
export interface UserIdData {
    userId: string;
}

const UserPlayerService = {
    getFeedPostStory: async (data: UserIdData) => {
        return requests.get(`/user/getFeedPostStory?userId=${data.userId}`);
    },
    getEventBadge: async (data: UserIdData) => {
        return requests.get(`/user/getEventBadge?userId=${data.userId}`);
    },
    getMessage: async (data: UserIdData) => {
        return requests.get(`/user/getMessage?userId=${data.userId}`);
    },
};

export default UserPlayerService;
