import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllCommunityList } from "../actions";

interface Community {
    skip: number;
    limit: number;
    sort?: {
        createdAt?: number;
        earning?: number;
        deposite?: number;
        active?: number;
    };
    search?: string;
    city?: string;
    [key: string]: any;
}

interface CommunityListState {
    communities: Community[];
    countDocuments: number;
    loading: boolean;
    error: string | null;
}

const initialState: CommunityListState = {
    communities: [],
    countDocuments: 0,
    loading: false,
    error: null,
};

const communityListSlice = createSlice({
    name: "communityList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllCommunityList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllCommunityList.fulfilled, (state, action: PayloadAction<{ data: Community[]; countDocuments: number; }>) => {
                state.loading = false;
                state.communities = action.payload.data;
                state.countDocuments = action.payload.countDocuments;
            })
            .addCase(getAllCommunityList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to fetch community list";
            });
    },
});

export default communityListSlice.reducer;
