import requests from "../Axios/Instance";

// Define parameters for fetching follower/following list
 

export interface FollowerFollowingListPlayerParams {
    userId: string;
    type:string;
    skip: number;
    limit: number;
    userType:string;
}

// Define user properties
export interface User {
    firstName: string;
    lastName: string;
    profile_image: string;
    status: boolean;
    userName: string;
    _id: string;
}

// Define the structure of the response data
export interface FollowerFollowingListResponseData {
    success: boolean;
    data: any;
    message: string;
}

// Service to fetch follower/following list
const FollowerFollowingPlayersService = {
    getFollowerFollowingListPlayer: async (data: FollowerFollowingListPlayerParams) => {
        return requests.get<FollowerFollowingListResponseData>(
            `/user/followerFollowingListing?userId=${data.userId}&type=${data.type}&skip=${data.skip}&limit=${data.limit}&userType=${data.userType}`
        );
    },
};

export default FollowerFollowingPlayersService;
