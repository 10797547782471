// src/services/communityDetailsServices.ts
import requests from "../Axios/Instance"; // Adjust the path according to your project structure

export interface PlayersDetailsResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}

const PlayersDetailsServices = {
    getPlayersDetails: async () => {
        return requests.get('/user/playerDetails');
    },
};

export default PlayersDetailsServices;
