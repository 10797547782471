import requests from "../Axios/Instance";

// Define parameters for fetching follower/following list
export interface FollowerFollowingListParams {
    userId: string;
    type: 'follower' | 'following';
    skip: number;
    limit: number;
}

 

// Define user properties
export interface User {
    firstName: string;
    lastName: string;
    profile_image: string;
    status: boolean;
    userName: string;
    _id: string;
}

// Define the structure of the response data
export interface FollowerFollowingListResponseData {
    success: boolean;
    data: {
        follower: User[];
        following: User[];
    };
    message: string;
}

// Service to fetch follower/following list
const FollowerFollowingService = {
    getFollowerFollowingList: async (data: FollowerFollowingListParams) => {
        return requests.get<FollowerFollowingListResponseData>(
            `/user/followerFollowingListing?userId=${data.userId}&type=${data.type}&skip=${data.skip}&limit=${data.limit}`
        );
    },
   
};

export default FollowerFollowingService;
