import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFollowerFollowingListPlayer } from "../actions";
 

export interface FollowerFollowingListPlayerParams {
    userId: string;
    type:string;
    skip: number;
    limit: number;
    userType:string;
}
// Define the initial state
interface FollowerFollowingState {
    loading: boolean;
    data: FollowerFollowingListPlayerParams[] | null;
    success: boolean;
    error: string | null;
    totalCount: number;
}

const initialState: FollowerFollowingState = {
    loading: false,
    data: [],  // Initialize as an empty array if it is expected to be an array
    success: false,
    error: null,
    totalCount: 0,
};

// Create the slice
const FollowerFollowingPlayersSlice = createSlice({
    name: "followerFollowingPlayers",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle pending state
            .addCase(getFollowerFollowingListPlayer.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            // Handle fulfilled state
            .addCase(getFollowerFollowingListPlayer.fulfilled, (state, action: PayloadAction<any>) => {
                console.log('Fulfilled action payload:', action.payload);  // Log the full payload
                if (action.payload?.data) {
                    state.data = action.payload.data;  // Only set data if it exists
                } else {
                    console.error('No data found in action.payload');
                }
                state.loading = false;
                state.success = action.payload?.success || true;  // Handle success fallback
                state.error = null;
                console.log("state.data", state.data);  // Check the state after setting data
            })
            // Handle rejected state
            .addCase(getFollowerFollowingListPlayer.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to fetch follower/following list";
                state.success = false;
            });
    },
});

export default FollowerFollowingPlayersSlice.reducer;
