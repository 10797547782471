import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTopCitiesWithHighest } from "../actions";

 
  interface TopCitiesWithHighest {
    _id: string;
    userCount: number;
    
  }
  
  interface ApiResponse {
    success: boolean;
    message: string;
    data: TopCitiesWithHighest[]; // Expecting an array of cities
  }
  
  interface TopCitiesWithHighestState {
    loading: boolean;
    data: TopCitiesWithHighest[]; // Initialize as an array
    error: string | null;
  }
  
  const initialState: TopCitiesWithHighestState = {
    loading: false,
    data: [], // Initial state as an empty array
    error: null,
  };
  
  const TopCitiesWithHighestSlice = createSlice({
    name: "topCitiesWithHighest",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getTopCitiesWithHighest.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getTopCitiesWithHighest.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
          if (action.payload.success) {
            state.data = action.payload.data; // Assuming `data` is an array of cities
          } else {
            state.error = action.payload.message;
          }
          state.loading = false;
        })
        .addCase(getTopCitiesWithHighest.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string || "Failed to fetch top cities with highest users";
        });
    },
  });
  
  export default TopCitiesWithHighestSlice.reducer;
  