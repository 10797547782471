import requests from "../Axios/Instance";

 
export interface TopCitiesWithHighest {  
  period: string;
}
 

const TopCitiesWithHighestServices= {
    getTopCitiesWithHighest: async (data: TopCitiesWithHighest) => {
        return requests.get(`/dashboard/topCitiesWithHighestUsers?period=${data.period}`);
    },
};

export default TopCitiesWithHighestServices;