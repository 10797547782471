import requests from "../Axios/Instance";

 

interface SignInData {
    // Define the shape of your sign-in data
    [key: string]: any;
}

 

const AuthServices = {
   
    customerSignIn: async (data: SignInData) => {
        return requests.post(`/user/login`, data);
    },
    customerLogout: async () => {
        return requests.get(`/user/logout`);
    },
   
};

export default AuthServices;
