import requests from "../Axios/Instance";

 
export interface AdvertisementParams {  
    _id: string;
    type:string;
    skip: number;
    limit: number;
}

const AdvertisementService = {
    getAdvertisementList: async (data: AdvertisementParams) => {
        return requests.get(`/advertisement/advertisementList?_id=${data._id}&skip=${data.skip}&type=${data.type}&limit=${data.limit}`);
    },
    getSponsorshipList: async (data: AdvertisementParams) => {
        return requests.get(`/advertisement/sponsorshipList?_id=${data._id}&skip=${data.skip}&type=${data.type}&limit=${data.limit}`);
    },
};

export default AdvertisementService;
