import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { customerSignIn, customerLogout } from "../actions";

interface User {
    [key: string]: any;
}

interface AuthState {
    user: User | null;
    isLoggedIn: boolean;
    isLoading: boolean;
    status: string;
    errorMessage?: string;
}

const initialState: AuthState = {
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : null,
    isLoggedIn: false,
    isLoading: false,
    status: "Idle",
};

const authSlice = createSlice({
    name: "AuthLogin",
    initialState,
    reducers: {
        setUser(state, { payload }: PayloadAction<User>) {
            state.user = payload;
            localStorage.setItem("user", JSON.stringify(payload));
        },
        logOut(state) {
            state.user = null;
            state.isLoggedIn = false;
            localStorage.removeItem("user");
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(customerSignIn.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(customerSignIn.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isLoggedIn = true;
                state.user = payload.data;
                localStorage.setItem("user", JSON.stringify(payload.data));
                toast.success(payload.message);
            })
            .addCase(customerSignIn.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isLoggedIn = false;
                state.errorMessage = payload ? payload.message : "An error occurred";
                toast.error(state.errorMessage);
            })
            .addCase(customerLogout.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(customerLogout.fulfilled, (state) => {
                state.isLoading = false;
                state.isLoggedIn = false;
                state.user = null;
                localStorage.removeItem("user");
            })
            .addCase(customerLogout.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload ? payload.message : "An error occurred during logout";
            });
    },
});

export const { setUser, logOut } = authSlice.actions;
export default authSlice.reducer;
