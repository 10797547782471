import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCommunityList } from "../actions";

interface Community {
    _id: string;
    skip: number;
    limit: number;
    [key: string]: any;
}

interface CommunityListState {
    selectedcommunities: Community[];
    loading: boolean;
    error: string | null;
}

interface CommunityListData {
    success: boolean;
    data: Community[];
    message: string;
}

const initialState: CommunityListState = {
    selectedcommunities: [],
    loading: false,
    error: null,
};

const SelectedCommunitySlice = createSlice({
    name: "selectedcommunityList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCommunityList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCommunityList.fulfilled, (state, action: PayloadAction<CommunityListData>) => {
                state.loading = false;
                state.selectedcommunities = action.payload.data;
            })
            .addCase(getCommunityList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to fetch community list";
            });
    },
});

export default SelectedCommunitySlice.reducer;
