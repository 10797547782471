import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPlayerList } from "../actions"; 

interface Player {
    _id: string;
    skip: number;
    limit: number;
    [key: string]: any;
}

interface SelectedPlayerState {
    selectedPlayers: Player[];
    loading: boolean;
    error: string | null;
}

interface ResponseData {
    success: boolean;
    data: Player[];
    message: string;
}

const initialState: SelectedPlayerState = {
    selectedPlayers: [],
    loading: false,
    error: null,
};

const SelectedPlayerSlice = createSlice({
    name: "selectedPlayer",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPlayerList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getPlayerList.fulfilled, (state, action: PayloadAction<ResponseData>) => {
                state.loading = false;
                state.selectedPlayers = action.payload.data;
            })
            .addCase(getPlayerList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to fetch selected player list";
            });
    },
});

export default SelectedPlayerSlice.reducer;
