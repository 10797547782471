import requests from "../Axios/Instance";

 
export interface AgeGenderStatistics {  
  period: string;
}
 

const AgeGenderStatisticsService= {
    getAgeGenderStatistics: async (data: AgeGenderStatistics) => {
        return requests.get(`/dashboard/getAgeGenderStatistics?period=${data.period}`);
    },
};

export default AgeGenderStatisticsService;